import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Empty from "../components/empty"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Cookies Policy" />
      <Empty />
    </Layout>
  )
}

export default IndexPage
